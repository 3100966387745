import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


new Vue({
  el: "#app",
  delimiters: ["[[", "]]"],
  components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
  },
  data() {
      return {
          csrfName: window.csrfTokenName,
          csrfToken: window.csrfTokenValue,
          swiperOptionTop: {
            spaceBetween: 10,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          },
          swiperOptionThumbs: {
            spaceBetween: 5,
            slidesPerView: 'auto',
            touchRatio: 0.2,
            slideToClickedSlide: true
          },
          swiperOptionCollection: {
            spaceBetween: 10,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
          },
            swiperOptionFeatured: {
              slidesPerView: 4,
              spaceBetween: 30,
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }
            }
        }
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  }
});

$( ".purchasableId" ).change(function(e) {
    $(".purchasableInfo").hide();
    $("#"+($(this).find(":selected").data('info'))).toggle();
});
